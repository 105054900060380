import * as React from "react";
import { Link, graphql } from "gatsby";
import Seo from "../../components/seo.js";
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../../components/layout";
import * as productsStyle from "../../styles/products.module.scss";

const ProductsPage = ({ data }) => {
  console.log(data);

  return (
    <Layout headOneOfPage="Moringa termékek, BIO Moringa teák és Moringa smoothie porok">
      <Seo title="Moringa termékek" lang="hu" />
      <div className={productsStyle.container}>
        {data.allMdx.nodes.map((node) => (
          <figure key={node.frontmatter.image.childImageSharp.id}>
            <Link
              className={productsStyle.link}
              to={`/moringa-products/${node.slug}`}
            >
              <GatsbyImage
                className={productsStyle.image}
                image={node.frontmatter.image.childImageSharp.gatsbyImageData}
                alt={node.frontmatter.image_alt}
              />
              <figcaption>{node.frontmatter.image_alt}</figcaption>
            </Link>
          </figure>
        ))}
      </div>
    </Layout>
  );
};

export const data = graphql`
  query {
    allMdx(sort: { fields: frontmatter___order, order: ASC }) {
      nodes {
        frontmatter {
          image_alt
          order
          image {
            childImageSharp {
              gatsbyImageData
              id
            }
          }
        }
        slug
      }
    }
  }
`;
export default ProductsPage;
